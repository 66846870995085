<template>
<div class="list-widget list-dash-items" :widget-id="widget.id" :widget-type="widget.type">
    <div v-for="item in news" :key="item.id" class="list-row hover bold">
        <a :href="`https://www.tgju.org/news/${item.id}/${item.slug}`" target="blank" class="list-col l-title dot no-border">
            <span>{{ item.title }}</span>
        </a>
        <div class="list-col l-item date no-border">
            <small>{{ item.jalali_created_time }}</small>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
.show-widget-settings-modal {
    display: flex;
    margin-left: 0;
    margin-right: auto;
}
</style>

<script>
// این کامپوننت اجزاری ویجت اخبار را دارا می باشد
import moment from "moment-jalaali";

export default {
    name: 'News',
    props: ['widget', 'data', 'editable', 'wrapper'],
    components: {},
    data: function () {
        return {
            news: []
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                this.news = this.data.news;
                // تزریق داده های جدید به اجزای کامپوننت
                this.loadData();
            }
        }, { immediate: true });
    },
    methods: {
        // این متد یک ویرایشی روی تاریخ های اخبار انجام میدهد	
        loadData() {
            this.news.forEach(item => {
                item.jalali_created_time = moment(item.created_time).format('jYYYY/jMM/jDD');
            });
        },
        // این متد وظیفه بازکردن مودال و هندل کردن کال بک مودال تنظیمات را بر عهده دارد
        open_settings_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

            let modal_data = {
                modal: 'widget_settings_modal',
                callbackEvent,
                component: 'Settings/News.vue',
                config: {
                    data: {
                        title: this.widget.title,
                        category_id: this.widget.data.category_id,
                    }
                }
            };

            modal_data = this.$helpers.unbindObject(modal_data);
            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (settings) => {
                this.widget.title = settings.title;
                this.widget.data.category_id = settings.category_id;

                let widget = this.$helpers.unbindObject(this.widget);

                this.$helpers.saveWidget(widget).then(() => {
                    this.$helpers.getWidgets({ widget_id: this.widget.id }).then((widget) => {
                        this.news = widget[0].data.news;
                        this.loadData();
                    });
                });
            });
        },
    },
}
</script>
